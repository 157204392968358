@import "./colors";
@import "./variables";

html {
  scroll-behavior: smooth;
}

:global(body.chiro-body) {
  background-color: white;
  font-family: $font-family;
  :global(.ui.header) {
    font-family: $font-family;
  }
  a {
    color: $link-color;
    transition: color 200ms ease-in-out;
    &:hover {
      color: rgba($link-color, .8);
    }
  }

  :global(.ui.breadcrumb) a {
    color: $link-color;
  }

  button, input, optgroup, select, textarea {
    font-family: $font-family;
  }
}

:global(.he.is.dead.jim.ui.container) {
  // unfxxk SemanticUI's extremely high priority
  // container width define.
  // See I am higher than you now!
  --mobile-main-content-width: #{$mobile-main-content-width};
  --sierra-color-type-main: #{$main-color};
  --sierra-color-type-main-lighter: #{$bondi-blue};

  @media only screen {
    width: $main-content-width;
  }
  @media only screen and (max-width: $mobile-main-content-max-width) {
    width: $mobile-main-content-width;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: $font-family;
  }
  :global(.ui.grid):first-child {
    margin-top: 1.5rem;
  }
}

.main-content {
  min-height: calc(100vh - #{$header-height + $footer-height});
}

:global(.ui.items>.item .meta), :global(.ui.items>.item .extra) {
  > *:not(:last-child) {
    &::after {
      content: ';';
    }
  }
  a::after {
    color: black;
    cursor: default;
    pointer-events: none;
  }
}
