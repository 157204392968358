@import "../../styles/colors";
@import "../../styles/variables";

.footer {
  position: absolute;
  box-sizing: border-box;
  width: 100vw;
  padding-top: 5rem;
  min-height: $footer-height;
  background-color: $main-color;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  
  :global(.ui.relaxed.grid) {
    min-width: 60%;
    margin-bottom: 0;
    color: white;
    a {
      color: white;
    }
  }

  :global(.ui.header) {
    color: white;
    font-size: 1.4rem;
    font-weight: 700;
    margin-bottom: .8rem;
  }
  ul {
    padding-left: 1rem;
    margin-top: .24rem;
    margin-bottom: .5rem;
    li {
      font-size: 1rem;
      line-height: 1.8;
    }
  }
  .copyright {
    background-color: $main-color;
    line-height: 2.5;
    text-align: center;
    color: white;
    min-width: 100%;
    a {
      color: white;
      text-decoration: underline;
      &:hover {
        color: white;
      }
    }
  }

  @media only screen {
    padding-left: #{(100vw - $main-content-width) / 2};
    padding-right: #{(100vw - $main-content-width) / 2};
  }
  @media only screen and (max-width: 1500px) {
    :global(.ui.relaxed.grid) {
      min-width: 50%;
    }
  }
  @media only screen and (max-width: 1350px) {
    .site-brand {
      margin-bottom: 2rem;
    }
    display: block;
    padding-top: 1rem;
    :global(.ui.relaxed.grid) {
      min-width: 90% !important;
    }
  }
  @media only screen and (max-width: $mobile-main-content-max-width) {
    padding-left: #{(100vw - $mobile-main-content-width) / 2};
    padding-right: #{(100vw - $mobile-main-content-width) / 2};
  }

  @media print {
    background-color: transparent;
    padding-top: 0;
    min-height: max-content;
    width: 100%;
    > *:not(.copyright) {
      display: none;
    }
    .copyright {
      background-color: transparent;
      color: black;
      a {
        color: black;
        &::after {
          content: "<" attr(href) ">";
          padding-left: .2rem;
        }
      }
    }
  }
}
