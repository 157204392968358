$green: #21BA45;
$cardinal: #8c191c;
$tarawera: #09425A;
$alto: #dddddd;
$pumice: #c7d1c6;
$fern-frond: #556222;
$reno-sand: #B96D12;
$bondi-blue: #009ABB;
$blue-lagoon: #007d92;
$wasabi: #80982a;
$chartreuse: #90ea00;
$picton-blue: #2ea3f2;
$cerulean: #0099e5;
$tutu: #fffeff;
$dustygray: #969696;
$thunder: #1c1b1c;
$lima: #7edd12;

$main-color: $blue-lagoon;
$nav-menu-toggle-hover: $picton-blue;
$nav-menu-border: $picton-blue;
$hl-bg-color: $alto;
$link-color: $blue-lagoon;
$link-current-color: $chartreuse;

$dividing-line: $dustygray;

$modal-bg: rgba($thunder, 0.5);
$modal-dialog-bg: $tutu;
$modal-close: $dustygray;
$modal-close-hover: $thunder;

$slider-title-bg: $lima;
