@import "styles/colors";
@import "styles/variables";
@import "~icosa/styles/default-colors";

body {
  @include default-color;
  --sierra-color-type-main: #{$main-color} !important;
  --sierra-color-type-main-lighter: #{$bondi-blue} !important;
  --sierra-color-type-highlight: #{$lima} !important;
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; // disallow horizontal scrolling

  @media print {
    display: relative;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sierra-sars2-webui {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mut-annot-editor-ui {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.genome-viewer-ui {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
