@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/functions";


header.chiro-header {
  --size-ratio: 1;
  display: flex;
  justify-content: space-between;
  background-color: $main-color;
  width: 100%;
  height: $header-height;
  font-family: $font-family;
  font-weight: 700;
  box-sizing: border-box;
  padding: 0 calc(var(--size-ratio) * 1rem) 0 calc(var(--size-ratio) * 4rem);

  .nav-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    font-size: calc(var(--size-ratio) * 1.2rem);
    text-transform: uppercase;
    font-weight: 700;

    @media print {
      display: none;
    }

    a.menu-toggle {
      display: none;
      color: white;
      font-size: calc(var(--size-ratio) * 1.5rem);
      font-weight: 800;
      transform: rotate(-90deg);
      touch-action: none;
      padding: calc(var(--size-ratio) * .7rem);
    }

    ul.nav {
      display: block;
      padding: 0 calc(var(--size-ratio) * 3rem) 0 0;
      margin: 0;

      li.nav-item {
        display: inline-block;
        margin-top: auto;
        margin-bottom: auto;
        font-size: calc(var(--size-ratio) * 1.1rem);
        padding: calc(var(--size-ratio) * .7rem);

        a {
          color: white;
          border-bottom: 2px solid transparent;
          transition: border-bottom-color .2s ease-in-out;
          &:hover {
            border-bottom-color: white;
          }
        }

        + li.highlight {
          padding-left: calc(var(--size-ratio) * .7rem);
        }

        &.current {
          a {
            color: $link-current-color;
            &:hover {
              border-bottom-color: $link-current-color;
            }
          }
        }

        &.highlight {
          padding: 0;
          a {
            display: inline-block;
            padding: calc(var(--size-ratio) * .7rem) calc(var(--size-ratio) * 1.4rem);
            border: 2px solid white;
            transition: all .4s ease-in-out;
            &:hover {
              color: $main-color;
              opacity: 1;
              background-color: white;
            }
          }
        }
      }
    }

  }
  @media only screen and (max-width: 1300px) {
    --size-ratio: .9;
    padding-left: 1rem;
    padding-right: 1rem;
    .nav-container {
      ul.nav {
        padding-right: 1rem;
      }
    }
  }

  @media only screen and (max-width: 1080px) {
    --size-ratio: .8;
  }
  @media only screen and (max-width: 1023px) {
    --size-ratio: .72;
  }

  @media only screen and (max-width: 940px) {
    padding-right: 0rem;
    .nav-container {
      position: relative;
      a.menu-toggle {
        display: block;
        margin-right: 1rem;
      }

      ul.nav {
        display: block;
        padding: 1rem 0 2rem;
        box-sizing: border-box;
        background-color: $main-color;
        border-top: 2px solid $nav-menu-border;
        position: absolute;
        right: 0;
        z-index: -10;
        opacity: 0;
        top: 80%;
        width: 50vw;
        transition: top .3s ease-in-out, opacity .6s ease-in-out;
        &.show {
          opacity: 1;
          z-index: 100;
          top: 100%;
        }
        li.nav-item {
          padding: 1.2rem;
          border-top: 1px solid rgba(0, 0, 0, .05);
          &:first-child {
            border-top: none;
          }
          display: block;
          + li.highlight {
            padding-left: 1.2rem;
            padding-top: 1.2rem;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 800px) {
    padding-left: 1rem;
    .nav-container {
      ul.nav {
        width: 75vw;
      }
    }
  }
  @media only screen and (max-width: 660px) {
    .nav-container {
      ul.nav {
        width: 100vw;
      }
    }
  }

  @media print {
    background-color: transparent;
    padding-left: 0;
  }

}
