@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/functions";


@mixin brand-size($scale) {

  font-weight: 700;
  font-family: $font-family;
  font-size: 1.8rem * $scale;
  .brand-logo {
    min-width: 65px * $scale;
    min-height: 65px * $scale;
  }

  .brand-sitename {
    height: 70px * $scale;
    &::before {
      mask-position: 0 0;
      mask-size: #{35% * sqrt($scale)} #{35% * sqrt($scale)};
    }
    padding-left: 1rem * $scale;

    .brand-sitename-title {
      padding-top: 2rem * $scale;
      font-size: 1.8rem * $scale;
    }

    .brand-sitename-subtitle {
      margin-top: 0.5rem * $scale;
      line-height: 1rem * $scale;
      font-size: 1rem * $scale;
    }

  }
  &.no-subtitle {
    .brand-sitename {
      &::before {
        mask-position-y: #{6px * $scale};
        mask-size: #{50% * sqrt($scale)} #{50% * sqrt($scale)};
      }
      .brand-sitename-title {
        padding-top: 3.1rem * $scale;
        font-size: 1.2rem * $scale;
      }
    }
  }

}

.brand-container {
  @include brand-size(.92);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  .brand-logo {
    background-color: white;
    display: block;
    mask: url(../../assets/corona.svg) no-repeat center center;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
  }

  .brand-sitename {
    color: white;
    display: block;
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      background-color: white;
      width: 100%;
      height: 100%;
      mask-image: url(../../assets/stanford.svg);
      mask-repeat: no-repeat;
      mask-clip: border-box;
    }
    vertical-align: baseline;
    text-transform: uppercase;
    a {
      color: white;
      &:hover {
        color: white;
      }
    }
    .brand-sitename-title {
      font-family: "Source Sans Pro", sans-serif;
      display: block;
      white-space: nowrap;
      line-height: 1em;
      margin: 0;
    }
    .brand-sitename-subtitle {
      display: block;
      white-space: nowrap;
      font-weight: 400;
      font-style: italic;
      text-transform: none;
      a {
        border-bottom: 1px solid transparent;
        transition: border-bottom-color .2s ease-in-out;
        &:hover {
          border-bottom-color: white;
        }
      }
    }
  }

  &.no-subtitle {
    .brand-sitename {
      color: #c9c9c9;
      a {
        color: #c9c9c9;
      }
    }
  }
  &.size-large {
    @include brand-size(1.1);
    &.responsive {
      @media only screen and (max-width: 1300px) {
        @include brand-size(.9);
      }
      @media only screen and (max-width: 600px) {
        @include brand-size(.8);
      }
    }
  }
  &.size-small {
    @include brand-size(.7);
  }
  &.responsive {
    @media only screen and (max-width: 1440px) {
      @include brand-size(.8);
    }
    @media only screen and (max-width: 1350px) {
      @include brand-size(.7);
    }
    @media only screen and (max-width: 1200px) {
      @include brand-size(.6);
    }
    @media only screen and (max-width: 600px) {
      @include brand-size(.5);
    }
  }

  @media print {
    .brand-logo {
      background-color: $main-color;
    }
    .brand-sitename {
      &::before {
        background-color: $main-color;
      }
      &, & a, & a:hover {
        color: $main-color;
      }
    }

  }
  
}
